'use client';

import React, { useEffect } from 'react';
import { smoothScroll } from '../../utils/animation/smoothScroll';
import { useRouter } from 'next/navigation';

interface HeroProps {
    title: string;
    subtitle: string;
    buttonText: string;
    buttonHref: string;
    rightTitle: string;
    rightDescription: string;
    backgroundImage: string;
}

const Hero: React.FC<HeroProps> = ({
    title,
    subtitle,
    buttonText,
    buttonHref,
    rightTitle,
    rightDescription,
    backgroundImage,
}) => {
    const router = useRouter();

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            e.preventDefault();
            const isAnchor = buttonHref.startsWith('#')

            if (isAnchor) {
                smoothScroll(buttonHref);
            } else {
                router.push(buttonHref);
            }

        };

        const button = document.querySelector<HTMLAnchorElement>('a[href="' + buttonHref + '"]');
        if (button) {
            button.addEventListener('click', handleClick as EventListener);
        }

        return () => {
            if (button) {
                button.removeEventListener('click', handleClick as EventListener);
            }
        };
    }, [buttonHref]);

    return (
        <div
            className="rh-hero relative bg-cover bg-center"
            id="hero"
            style={{ backgroundImage: `url('${backgroundImage}')` }}
        >
            <div className="relative container mx-auto px-4 pt-32 sm:pt-40 xl:pt-60 pb-12 sm:pb-24 sm:px-6 lg:px-8 flex items-center">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 sm:gap-8 items-center">
                    <div className="">
                        <h1 className="pb-4 sm:mb-4">{title}</h1>
                        <p className="mb-12 max-w-72">{subtitle}</p>
                        <a href={buttonHref} className="rh-btn-black-lg">{buttonText}</a>
                    </div>
                    <div className="flex flex-col justify-end h-full">
                        <div className="mt-auto">
                            <h2>{rightTitle}</h2>
                            <p className="mb-2">{rightDescription}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;
